import React, { useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import loadable from '@loadable/component'
import { useLightboxStore } from '../../stores/lightbox'
import { findActiveIndex } from '../../helpers/overlay'
import CloseOverlay from '../../svg/close-overlays-forms.svg'

const Slides = loadable(() => import('./Slides'))

const Lightbox = () => {
  const {
    showGallery,
    setShowGallery,
    images,
    closeCarousel,
  } = useLightboxStore()
  const content = useRef()

  // Close gallery on 'esc' keypress
  const handleEscape = evt => {
    if (!showGallery) return
    if (evt.key === 'Escape') setShowGallery(false)
  }

  const handleTab = evt => {
    // Select all focusable elements
    const focusableElements = content.current.querySelectorAll(
      'a[href], button'
    )

    if (!evt.shiftKey) {
      let nextIndex = findActiveIndex(focusableElements)

      // If activeIndex + 1 larger than array length focus first element otherwise focus next element
      nextIndex + 1 === focusableElements.length
        ? (nextIndex = 0)
        : (nextIndex += 1)

      focusableElements[nextIndex].focus()

      return evt.preventDefault()
    }

    if (evt.shiftKey) {
      let prevIndex = findActiveIndex(focusableElements)

      // if activeIndex - 1 less than 0 focus last element otherwise focus previous element
      prevIndex - 1 < 0
        ? (prevIndex = focusableElements.length - 1)
        : (prevIndex -= 1)

      focusableElements[prevIndex].focus()

      return evt.preventDefault()
    }
  }

  const handleClick = evt => {
    // Close gallery on bg click
    if (evt) evt.preventDefault()
    setShowGallery(false)
  }

  // map of keyboard listeners
  const keyListenersMap = new Map([
    [27, handleEscape],
    [9, handleTab],
  ])

  const handleKeydown = evt => {
    // get the listener corresponding to the pressed key
    const listener = keyListenersMap.get(evt.keyCode)

    // call the listener if it exists
    return listener && listener(evt)
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  })

  useEffect(() => {
    // Cache current activeElement
    const { activeElement } = document

    return () => {
      activeElement.focus()
    }
  }, [])

  return (
    <motion.section
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: showGallery ? 1 : 0,
      }}
      className={`grid place-items-center fixed inset-0 w-screen h-screen bg-black bg-opacity-90 z-50 ${
        showGallery ? 'pointer-events-auto' : 'pointer-events-none'
      }`}
      onClick={handleClick}
    >
      <div
        className="relative w-[95vw] h-[95vh] overflow-hidden  "
        onClick={evt => evt.stopPropagation()}
        ref={content}
      >
        <div className="inner">
          <button
            type="button"
            className="absolute top-0 right-0 z-50 flex items-center mt-4 mr-4"
            onClick={() => setShowGallery(!showGallery)}
          >
            <div className="mt-1 mr-2 text-blue-200 opacity-75 text-14 md:hidden">
              Close
            </div>
            <CloseOverlay />
          </button>
          <Slides images={images} />
        </div>
      </div>
    </motion.section>
  )
}

export default Lightbox
